import * as React from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SetupSuccessSubnav from '../../components/setup_success_subnav'
import Container from '../../components/container'
import { StaticImage } from 'gatsby-plugin-image'
import SalesVideo from '../../components/sales_video'
import VideoModal from '../../components/video_modal'
import Anchor from '../../components/anchor'

const HiwDealerSupportPage = () => (
  <Layout activeNav={true}>
    <Seo
      title='Dealer Support'
      description='We’re your long-term business partner, here to help you evolve your processes and optimize the use of digital retailing platform to become more successful.'
    />
    <div className='flex flex-col justify-center relative bg-black text-white'>
      <StaticImage
        src='../../images/support-hero.jpg'
        alt=''
        placeholder='blurred'
        quality={100}
        className='absolute inset-0'
        imgStyle={{ objectPosition: 'center 22%' }}
      /> 
      <Container className='relative pb-4 pt-5 sm:pb-5 text-white'>
        <h1 className='font-semi-bold text-4vw'>
          Dealer Support
        </h1>
        <p className='text-md sm:text-lg mb-0'>
          Dedicated, 1:1 support comes standard with every contract.
        </p>
      </Container>
    </div>
    <SetupSuccessSubnav />
    <div className='Section pb-0 sm:pb-4'>
      <Container size='lg' className='leading-relaxed text-md'>
        <div className='grid gap-4 sm:grid-cols-2 sm:gap-5'>
          <div className='sm:text-center col-span-full'>
            <h2 className='font-light text-xl sm:text-2xl mt-0'>
              <img alt='' height='60' src='https://media.roadster.com/dealersales/support-icon-white-gloves.svg' width='66' className='block sm:mx-auto mb-2' />
              White Glove Service
            </h2>
            <p className='max-w-measure-4 mx-auto'>
              We’re not a vendor who offers basic training, a pat on the back and a wish of good luck. We’re your long-term business partner, here to help you evolve your processes and optimize the use of our platform to become more successful. Our Integrations and Support teams will listen and learn about your business, and develop customized, scalable strategies to help you smoothly transition towards omnichannel commerce at your own pace.
            </p>
          </div>
          <div>
            <h2 className='font-light text-xl sm:text-2xl mt-0'>
              <img alt='' height='57' src='https://media.roadster.com/dealersales/support-icon-experts.svg' width='65' className='block mb-2' />
              Industry Experts
            </h2>
            <p>
              Every Roadster partner is assigned a dedicated Dealer Success Manager. Our DSMs are subject matter experts in both automotive and retail. Beyond helping you optimize the use of our platform, these trusted advisors can also consult you in all areas of the business — process flow optimization, lead management, digital ad trends and more. Leverage their deep industry insights to help your dealership grow in the green field of digital retail.
            </p>
          </div>
          <div>
            <h2 className='font-light text-xl sm:text-2xl mt-0'>
              <img alt='' height='57' src='https://media.roadster.com/dealersales/support-icon-added-value.svg' width='64' className='block mb-2' />
              Added Value
            </h2>
            <p>
              When we visit your store, it’s not to drop off donuts; it’s to deliver real value. Each time, we’ll dive deeply into data and analytics. We’ll show you benchmarks for success and how you’re performing against competitors. We’ll expand upon your processes and focus on ways to optimize your business. We’ll also gather feedback so that we can continually improve our products for you.
            </p>
          </div>
        </div>
      </Container>
    </div>
    <div className='Section text-md leading-relaxed sm:pb-5'>
      <Container size='lg'>
        <div className='text-center'>
          <h2 className='text-xl sm:text-2xl font-light text-center mt-0'>
            Details, Please!
          </h2>
          <p className='mb-4 sm:mb-5'>
            10 ways we set you up for success at every step.
          </p>
        </div>
        <div className='grid sm:grid-cols-3 gap-4'>
          <div>
            <div className='CircleNum'>
              1
            </div>
            <h3 className='mt-3 mb-2'>
              Preliminary calls
            </h3>
            <p>
              We’ll listen and learn about your store to gain a deep understanding of its processes and pricing strategy. Then, we’ll identify areas of opportunity and advise you on how to best build your Express Store. Finally, we’ll offer you a preview of your Express Store, introduce you to your Dealer Success Manager, schedule your training and let you know what we will cover.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              2
            </div>
            <h3 className='mt-3 mb-2'>
              Implementation and systems integration
            </h3>
            <p>
              Our Implementation Team will set up an inventory feed through your existing provider. They’ll load your specified finance and lease rates, layering in all applicable incentives, rebates, taxes, service plans and accessories. They’ll also integrate your current systems — website providers, CRM companies, lending solutions, etc. —  into our platform to help your team function as efficiently and effectively as possible.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              3
            </div>
            <h3 className='mt-3 mb-2'>
              Compliance clearance
            </h3>
            <p>
              Whether it’s OEM compliance, regional market compliance or your own dealership’s legal compliance — we’ll go through the necessary steps to ensure you’re in the clear before launch day.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              4
            </div>
            <h3 className='mt-3 mb-2'>
              Roadster Academy
            </h3>
            <p>
              Our guided learning portal allows you to self-educate about our platform on your own time. With separate multimedia courses and quizzes designed for each role within your dealership, everyone can become “Express Storefront Certified” prior to training day.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              5
            </div>
            <h3 className='mt-3 mb-2'>
              Onsite activation and training
            </h3>
            <p>
              We’ll be at your dealership on launch day (and the day after) to train your entire staff on the customer journey, as well as the sales agent and deal-management tools. We’ll start with a management meeting to customize our training to your existing process. Then we’ll conduct both large-group demos and one-on-one training. We’ll solidify everyone’s knowledge of our tools by using them in live scenarios with customers.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              6
            </div>
            <h3 className='mt-3 mb-2'>
              Personalized follow-up and store visits
            </h3>
            <p>
              Your DSM will call you every few weeks to make sure everything is going smoothly. You might discuss setting up your CRM, on-boarding new hires, modifying configurations and reviewing our reporting suite. They will also visit your dealership every 6 weeks to review customized reports, identify any gaps in your processes and advise you on how to optimize the use of our products.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              7
            </div>
            <h3 className='mt-3 mb-2'>
              Quarterly and end-of-year reviews
            </h3>
            <p>
              Your DSM will provide you with periodic written reports detailing the evolution of your time on our platform. They will also indicate areas of improvement and forecast where you’re headed.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              8
            </div>
            <h3 className='mt-3 mb-2'>
              Reporting suite and resource library
            </h3>
            <p>
              Optimize your Express Store where you need it most. You can review general reports in your Dealer Admin. You can also download play books and best practices for implementation, lead follow-up, in-store processes, trade valuation, F&I, marketing and more.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              9
            </div>
            <h3 className='mt-3 mb-2'>
              Live webinars and “Office Hours”
            </h3>
            <p>
              Brush up on Express Storefront basics every Tuesday with live webinars. Ask our experts your questions every Thursday during Roadster Office Hours.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              10
            </div>
            <h3 className='mt-3 mb-2'>
              Support that never stalls
            </h3>
            <p>
              Your dedicated DSM is almost always available to you via email, text or call. If you ever need backup assistance, you can reach our live Dealer Support Team 24/7 at
              {' '}
              <a href='mailto:dealersupport@roadster.com' className='hover:underline'>
                dealersupport@roadster.com
              </a>
              {' '}
              or
              {' '}
              <span className='inline-block'>800-755-7355.</span>
            </p>
          </div>
        </div>
      </Container>
    </div>
    <Anchor id='faces_of_success' />
    <div className='Section Section--stripe'>
      <Container size='lg'>
        <h2 className='text-2xl lg:text-3xl text-center mt-0 mb-4 md:mb-5'>Faces of Success</h2>
        <div className='grid md:grid-cols-2 items-center gap-3 sm:gap-4 lg:gap-5'>
          <div>
            <VideoModal videoId='637221477'>
              <button type='button' className='block w-full relative'>
                <StaticImage
                  src='../../images/facesofsuccess_poster.jpg'
                  alt=''
                  placeholder='blurred'
                  quality={100}
                />
                <StaticImage
                  alt='Play Video'
                  src='../../images/icon-play.png'
                  className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hover:opacity-60 transition-opacity duration-300'
                  placeholder='tracedSVG'
                  style={{ width: '15%' }}
                />
              </button>
            </VideoModal>
          </div>
          <div>
            <h3 className='text-xl lg:text-2xl font-semi-bold mt-0'>
              Meet Dealer Success Manager Devon Owen!
            </h3>
            <p className='text-md leading-relaxed mb-0'>
              In this two-minute video Q&A, you’ll learn what success looks like for the dealer-partners she supports, how she guides them towards it and how your Roadster DSM can help you achieve next-level results.
            </p>
          </div>
        </div>
      </Container>
    </div>
    <div className='Section text-white bg-dark-gray text-center'>
      <Container size='sm'>
        <div className='font-serif font-semi-bold italic leading-none text-gray' style={{ fontSize: '12rem' }}>
          ”
        </div>
        <div className='Testimonial'>
          Each of you [Integrations Team and Dealer Success Team members] has been nothing but spectacular in working with our team — whether it was taking a phone call at an odd time or answering one of my thousand emails. It is easy to see why Roadster is where it is today. Here’s to an incredible partnership!
        </div>
        <div className='sm:text-md mt-3'>
          <div>
            Joe Lukich
          </div>
          <div className='font-semi-bold'>
            Strategic Variable Operations Director<br />#1 Cochran Automotive Group
          </div>
        </div>
      </Container>
    </div>
    <SalesVideo />
  </Layout>
)

export default HiwDealerSupportPage

import * as React from 'react'
import Container from './container'
import { StaticImage } from 'gatsby-plugin-image'
import Button from './button'
import VideoModal from './video_modal'

const SalesVideo = () => (<VideoModal videoId='456996516'>
  <button type='button' className='Section pt-0 sm:pb-0 relative sm:text-white'>
    <div className='relative'>
      <StaticImage
        src='../images/sales-video-poster.jpg'
        alt=''
        placeholder='blurred'
      />
      <StaticImage
        alt='Play Video'
        src='../images/icon-play.png'
        className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 sm:hidden'
        placeholder='tracedSVG'
        style={{ width: '15%' }}
      />
    </div>
    <div className='sm:absolute inset-0 flex flex-col justify-center'>
      <div>
        <Container>
          <div className='sm:flex'>
            <div className='sm:size-1of2 md:size-3of8'>
              <h2 className='text-xl md:text-2xl lg:text-3xl font-light'>
                Everyone wins with a seamless retail experience.
              </h2>
              <p className='text-md leading-relaxed max-w-measure-2 mb-0'>
                See how Omnichannel Commerce helps dealerships sell more efficiently, while also driving customer engagement and satisfaction.
              </p>
              <div className='sm:hidden mt-3'>
                <Button
                  as='span'
                  variant='dark'
                  children='Watch Video'
                />
              </div>
            </div>
            <div className='hidden sm:block flex-1 relative text-white'>
              <StaticImage
                alt='Play Video'
                src='../images/icon-play.png'
                className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-2of10 md:size-4of10 hover:opacity-60 transition-opacity duration-300'
                placeholder='tracedSVG'
              />
            </div>
            <div className='hidden md:block size-3of8'></div>
          </div>
        </Container>
      </div>
    </div>
  </button>
</VideoModal>)

export default SalesVideo
